/**
 * Module dependencies.
 */

import { routeResolve } from 'src/core/utils/routes';

/**
 * Export `MenuItem` type.
 */

export type MenuItem = {
  key: string;
  url?: string;
};

/**
 * Export `NavbarMenuProps` type.
 */

export type NavbarMenuProps = {
  items?: MenuItem[];
  key: MenuItem['key'];
  type?: 'dropdown';
  url?: MenuItem['url'];
}[];

/**
 * Export `navbarMenu` constant.
 */

export const navbarMenu: NavbarMenuProps = [
  {
    key: 'home',
    url: routeResolve('home')
  },
  {
    items: [
      {
        key: 'serviceVerificar360',
        url: routeResolve('serviceVerificar360')
      },
      {
        key: 'serviceGarantias',
        url: routeResolve('serviceGarantias')
      },
      {
        key: 'serviceRegisto',
        url: routeResolve('serviceRegisto')
      },
      {
        key: 'serviceScanDeBateria',
        url: routeResolve('serviceScanDeBateria')
      }
    ],
    key: 'services',
    type: 'dropdown'
  },
  {
    key: 'prices',
    url: routeResolve('prices')
  },
  {
    key: 'contacts',
    url: routeResolve('contacts')
  }
];

/**
 * Export `MobileMenuProps` type.
 */

export type MobileMenuProps = {
  badgeKey?: 'base' | 'extra';
  hasSpaceLeft?: boolean;
  key: string;
  size?: 'large' | 'small';
  url: string;
};

/**
 * Export `mobileMenu` constant.
 */

export const mobileMenu: MobileMenuProps[] = [
  {
    key: 'home',
    size: 'large',
    url: routeResolve('home')
  },
  {
    badgeKey: 'base',
    key: 'serviceVerificar360',
    size: 'large',
    url: routeResolve('serviceVerificar360')
  },
  {
    badgeKey: 'extra',
    hasSpaceLeft: true,
    key: 'serviceGarantias',
    url: routeResolve('serviceGarantias')
  },
  {
    badgeKey: 'extra',
    hasSpaceLeft: true,
    key: 'serviceRegisto',
    url: routeResolve('serviceRegisto')
  },
  {
    key: 'serviceScanDeBateria',
    size: 'large',
    url: routeResolve('serviceScanDeBateria')
  }
];

/**
 * Export `mobileFooterMenu` constant.
 */

export const mobileFooterMenu = [
  {
    key: 'prices',
    url: routeResolve('prices')
  },
  {
    key: 'faqs',
    url: routeResolve('faqs')
  },
  {
    key: 'contacts',
    url: routeResolve('contacts')
  },
  {
    key: 'legal',
    url: routeResolve('legal')
  }
];
