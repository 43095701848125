/**
 * Module dependencies.
 */

import { routeResolve } from 'src/core/utils/routes';

/**
 * Export `footerMenu` constant.
 */

export const footerMenuServices = [
  {
    key: 'serviceVerificar360',
    mb: 'var(--space-sm)',
    theme: 'base',
    url: routeResolve('serviceVerificar360')
  },
  {
    key: 'serviceGarantias',
    mb: 'var(--space-xs)',
    theme: 'extra',
    url: routeResolve('serviceGarantias')
  },
  {
    key: 'serviceRegisto',
    mb: 'var(--space-sm)',
    theme: 'extra',
    url: routeResolve('serviceRegisto')
  },
  {
    additionalText: true,
    key: 'serviceScanDeBateria',
    mb: 'var(--space-xs)',
    url: routeResolve('serviceScanDeBateria')
  }
];

/**
 * Export `footerMenuPages` constant.
 */

export const footerMenuPages = [
  {
    key: 'prices',
    url: routeResolve('prices')
  },
  {
    key: 'faqs',
    url: routeResolve('faqs')
  },
  {
    key: 'terms',
    url: routeResolve('legal')
  },
  {
    key: 'contacts',
    url: routeResolve('contacts')
  }
];
