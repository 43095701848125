/**
 * Module dependencies.
 */

import { DetailedHTMLProps, ElementType, HTMLAttributes, PropsWithoutRef, ReactNode, forwardRef } from 'react';
import { FadeInUp } from 'src/components/core/animations/fade-in-up';
import { IconLabel, IconLabelProps } from 'src/components/core/icon-label';
import { RawHtml } from '@untile/react-core/components/raw-html';
import { Text, textStyles } from 'src/components/core/text';
import { media } from '@untile/react-core/styles/media';
import styled from 'styled-components';

/**
 * `HeadingType` type.
 */

type HeadingType = PropsWithoutRef<DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>>;

/**
 * DescriptionSize type.
 */

type DescriptionSize = 14 | 16 | 18;

/**
 * DescriptionTheme type.
 */

type DescriptionTheme = 'cyan50' | 'slate50' | 'slate100' | 'slate300' | 'slate900';

/**
 * TitleSize type.
 */

type TitleSize = 14 | 22 | 28 | 36;

/**
 * TitleTheme type.
 */

type TitleTheme = 'cyan50' | 'slate50' | 'slate900' | 'gradient-primary';

/**
 * Props type.
 */

type Props = IconLabelProps & {
  as?: ElementType;
  asDescription?: ElementType;
  asLead?: ElementType;
  asTitle?: ElementType;
  children?: ReactNode;
  description?: ReactNode;
  descriptionSize?: DescriptionSize;
  descriptionTheme?: DescriptionTheme;
  hasMaxWidth?: boolean;
  href?: string;
  isDescriptionRaw?: boolean;
  lead?: string;
  offset?: boolean;
  shouldAnimate?: boolean;
  staggerMobile?: boolean;
  title?: string;
  titleSize?: TitleSize;
  titleTheme?: TitleTheme;
};

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  margin-bottom: var(--space-xs);

  > span {
    display: block;
    width: fit-content;
  }

  ${media.max.md`
    &[data-offset='true'] {
      padding-inline: var(--space-sm);
    }
  `}
`;

/**
 * `Title` styled component.
 */

const Title = styled(Text)`
  &[data-size='36'] {
    ${textStyles.h1};
  }

  &[data-size='28'] {
    ${textStyles.h3};
  }

  &[data-size='22'] {
    ${textStyles.h4};
  }

  &[data-size='14'] {
    ${textStyles.titleM};
  }

  &[data-size] {
    font-weight: 600;
    margin-bottom: var(--space-xs);
  }

  &[data-theme='cyan50'] {
    color: var(--color-cyan50);
  }

  &[data-theme='slate50'] {
    color: var(--color-slate50);
  }

  &[data-theme='slate900'] {
    color: var(--color-slate900);
  }

  &[data-theme='gradient-primary'] {
    background: var(--gradient-primary--0-deg);
    -webkit-background-clip: text;
    background-clip: text;
    color: var(--color-primary);
    -webkit-text-fill-color: transparent;
  }
`;

/**
 * `Lead` styled component.
 */

const Lead = styled(Text)`
  ${textStyles.h4};

  color: var(--color-primary);
  font-weight: 600;
  margin-bottom: var(--space-xs);
`;

/**
 * `Description` styled component.
 */

const Description = styled(Text)`
  &[data-size='14'] {
    ${textStyles.bodyM};
  }

  &[data-size='16'] {
    ${textStyles.bodyL};
  }

  &[data-size='18'] {
    ${textStyles.bodyXl};
  }

  &[data-theme='cyan50'] {
    color: var(--color-cyan50);
  }

  &[data-theme='slate50'] {
    color: var(--color-slate50);
  }

  &[data-theme='slate100'] {
    color: var(--color-slate100);
  }

  &[data-theme='slate300'] {
    color: var(--color-slate300);
  }

  &[data-theme='slate900'] {
    color: var(--color-slate900);
  }
`;

/**
 * `StyledRawDescription` styled component.
 */

const RawDescription = styled(Description)`
  font-size: inherit;
  line-height: inherit;

  b {
    color: var(--color-slate300);
    font-weight: inherit;
  }
`;

/**
 * Export `Heading` component.
 */

export const Heading = forwardRef<HTMLDivElement, Props & HeadingType>((props, ref) => {
  const {
    asDescription,
    asLead,
    asTitle,
    children,
    description,
    descriptionSize = 18,
    descriptionTheme = 'slate100',
    hasMaxWidth,
    icon,
    isDescriptionRaw,
    label,
    labelSize,
    lead,
    offset,
    shouldAnimate,
    staggerMobile,
    title,
    titleSize = 36,
    titleTheme = 'cyan50',
    ...rest
  } = props;

  if (!icon && !label && !title && !lead && !description) {
    return null;
  }

  return (
    <Wrapper
      data-offset={offset}
      ref={ref}
      {...rest}
      {...(hasMaxWidth && { style: { maxWidth: 'var( --heading-max-width)' } })}
    >
      <IconLabel
        icon={icon}
        label={label}
        labelSize={labelSize}
        shouldAnimate={shouldAnimate}
        staggerMobile={staggerMobile}
        style={{ marginBottom: 'var(--space-xs)' }}
      />

      {title && (
        <FadeInUp shouldAnimate={shouldAnimate} staggerMobile={staggerMobile}>
          <Title {...(asTitle && { as: asTitle })} data-size={titleSize} data-theme={titleTheme}>
            {title}
          </Title>
        </FadeInUp>
      )}

      {lead && (
        <FadeInUp shouldAnimate={shouldAnimate} staggerMobile={staggerMobile}>
          <Lead {...(asLead && { as: asLead })}>{lead}</Lead>
        </FadeInUp>
      )}

      {description && (
        <FadeInUp shouldAnimate={shouldAnimate} staggerMobile={staggerMobile}>
          <Description
            {...(asDescription && { as: asDescription })}
            data-size={descriptionSize}
            data-theme={descriptionTheme}
          >
            {!isDescriptionRaw ? description : <RawHtml element={RawDescription}>{description}</RawHtml>}
          </Description>
        </FadeInUp>
      )}

      {children && (
        <FadeInUp shouldAnimate={shouldAnimate} staggerMobile={staggerMobile} style={{ paddingTop: '48px' }}>
          {children}
        </FadeInUp>
      )}
    </Wrapper>
  );
});

/**
 * `Heading` display name.
 */

Heading.displayName = 'Heading';
